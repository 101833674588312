<template>
  <div class="id-card">
    <component :is="`style`">
      .card-one-wrapper {
      font-family: "Oxygen" !important,
      sans-serif;
      }
      .lenon-id-card-three {
      width: 500px;
      height: 300px;
      background-color: {{ customization.alt_bg_color }};
      /* background-color: rgb(92, 223, 92); */
      margin: 5px;
      position: relative;
      padding: 0px !important;
      /* transform: rotate(90deg); */
      overflow: hidden;
      border: 1px dashed black;
      }
      .lenon-card-rectangle {
      width: 500px;
      height: 230px;
      position: absolute;
      top: 0px;
      /* border-bottom-right-radius: 30px; */
      border-bottom-left-radius: {{ customization.inner_card_radius }}px;
      }
      #barcode {
      /* height: 60px; */
      /* background-color: black; */
      position: absolute;
      cursor: pointer;
      bottom: 10px;
      right: 5px;
      }
      .profile {
      height: 140px;
      width: 140px;
      position: absolute;
      border-radius: {{ customization.photo_border_radius }}px;
      object-fit: contain;
      top: 80px;
      right: 30px;
      cursor: pointer;
      }
      .company-logo img {
      position: absolute;
      left: 30px;
      top: 50px;
      cursor: pointer;
      /* top: 10px; */
      /* padding: 5px; */
      /* background-color: white; */
      }
      .company-name {
      position: absolute;
      top: 10px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      color: {{ customization.text_color }};
      font-size: 20px;
      text-transform: uppercase;
      cursor: pointer;
      }
      .id-card-title {
      position: absolute;
      top: 35px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      color: {{ customization.text_color }};
      text-transform: uppercase;
      margin: 0px !important;
      padding: 5px !important;
      font-size: 16px !important;
      font-weight: bolder;
      cursor: pointer;
      text-decoration:underline;
      }
      .member-information {
      position: absolute;
      right: 180px;
      top: 110px;
      text-align: left;
      color: {{ customization.text_color }};
      cursor: pointer;
      }
      .member-information .name {
      font-size: 14px;
      font-weight: bolder;
      text-decoration: underline;
      }
      .member-information .member-id {
      font-size: 14px;
      font-weight: 400;
      }
      .member-information .dob {
      font-size: 14px;
      font-weight: 200;
      }
      .member-information .org {
      font-size: 14px;
      font-weight: 200;
      }
      .issued-at {
      position: absolute;
      bottom: 25px;
      left: 20px;
      font-size: 14px;
      text-decoration: underline;
      cursor: pointer;
      color: {{ customization.issued_date_text_color }};
      }
    </component>
    <div
        style="display: flex; flex-direction: column; align-items: center"
        class="card-one-wrapper"
    >
      <div class="lenon-id-card-three">
        <div
            class="lenon-card-rectangle"
            :style="customization.gradient?
            `background-image: linear-gradient(${customization.first_color}, ${customization.second_color};`
            :`background-color:${customization.bg_color}`"
        >
          <div
              class="company-name"
              @click="(e)=>{startDragging(e,'company')}"
              @mousemove="(e)=>{drag(e,'company')}"
          >
            <strong>{{ schoolName }}</strong>
          </div>
          <div
              class="id-card-title"
              @click="(e)=>{startDragging(e,'title')}"
              @mousemove="(e)=>{drag(e,'title')}"
          >
            <p>STUDENT ID CARD</p>
          </div>
          <div
              v-if="customization.selected.includes('logo') && schoolLogo"
              class="company-logo"
              @click="(e)=>{startDragging(e,'logo')}"
              @mousemove="(e)=>{drag(e,'logo')}"
          >
            <img
                width="50"
                height="50"
                :style="`border-radius: 50px;background-color: ${customization.logo_bg};padding: 3px`"
                :src="schoolLogo"
                alt=""
            >
          </div>
          <div
              class="profile"
              @click="(e)=>{startDragging(e,'photo')}"
              @mousemove="(e)=>{drag(e,'photo')}"
          >
            <img
                width="140"
                height="140"
                :style="`border-radius: ${customization.photo_border_radius}px; border: 5px solid ${customization.alt_bg_color}`"
                src="https://cambodiaict.net/wp-content/uploads/2019/12/computer-icons-user-profile-google-account-photos-icon-account.jpg"
                alt=""
            >
          </div>
          <div
              class="member-information"
              @click="(e)=>{startDragging(e,'member')}"
              @mousemove="(e)=>{drag(e,'member')}"
          >
            <div class="name">
              Rita Naana Ora Kusi Frimpong
            </div>
            <div
                v-if="customization.selected.includes('id')"
                class="member-id"
            >
              <strong>ID#</strong> S220010822
            </div>
            <div
                v-if="customization.selected.includes('dob')"
                class="dob"
            >
              <strong>DoB</strong> Aug 20, 2010
            </div>
            <div
                v-if="customization.selected.includes('pphone')"
                class="org"
            >
              <strong>Emergency</strong> 0249039928
            </div>
            <div
                v-if="customization.selected.includes('gender')"
                class="gender"
            >
              <strong>Gender</strong> FEMALE
            </div>
          </div>
        </div>
        <div
            class="issued-at"
            @click="(e)=>{startDragging(e,'issued')}"
            @mousemove="(e)=>{drag(e,'issued')}"
        >
          <strong>Issued on</strong> 20th Jan 2021
        </div>
        <svg
            id="barcode"
            @click="(e)=>{startDragging(e,'barcode')}"
            @mousemove="(e)=>{drag(e,'barcode')}"
        />
      </div>
    </div>
  </div>

</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
const JsBarcode = require('jsbarcode')

export default {
  name: 'Card1',
  props: {
    customization: { type: Object, default: () => {} },
  },
  data() {
    return {
      logoDragging: false,
      photoDragging: false,
      memberDragging: false,
      companyDragging: false,
      titleDragging: false,
      barcodeDragging: false,
      issuedDragging: false,
      mouse_x: 0,
      mouse_y: 0,
    }
  },
  computed: {
    schoolName() {
      return this.$store.getters['auth/school'].name
    },
    schoolLogo() {
      return `${process.env.VUE_APP_STORAGE_PATH}/${this.$store.getters['auth/schoolLogo']}`
    },
    barcodeColor() {
      return this.customization.barcode_color
    },
  },
  watch: {
    barcodeColor(color) {
      JsBarcode('#barcode', '1234567891', {
        width: 3,
        height: 35,
        displayValue: false,
        background: ' rgba(255, 255, 255, 0);',
        lineColor: color,
      })
    },
  },
  mounted() {
    JsBarcode('#barcode', '1234567891', {
      width: 3,
      height: 35,
      displayValue: false,
      background: ' rgba(255, 255, 255, 0);',
      lineColor: this.customization.barcode_color,
    })
  },
  methods: {
    startDragging(event, element) {
      // const dragging = `${element}Dragging`
      // const x = `${element}_x`
      // const y = `${element}_y`
      // this[dragging] = !this[dragging]
      // this.mouse_x = event.clientX - this.customization[x]
      // this.mouse_y = event.clientY - this.customization[y]
    },
    drag(event, element) {
      //   const dragging = `${element}Dragging`
      //   const x = `${element}_x`
      //   const y = `${element}_y`
      //   if (this[dragging]) {
      //     this.customization[x] = event.clientX - this.mouse_x
      //     this.customization[y] = event.clientY - this.mouse_y
      //
      //     // Constrain the logo within the ID card bounds
      //     const cardWidth = 500 // Adjust this to your card width
      //     const cardHeight = 300 // Adjust this to your card height
      //
      //     if (this.customization[x] < 0) this.customization[x] = 0
      //     if (this.customization[y] < 0) this.customization[y] = 0
      //     if (this.customization[x] > cardWidth) this.customization[x] = cardWidth
      //     if (this.customization[y] > cardHeight) this.customization[y] = cardHeight
      //   }
      //   localStorage.setItem('cus', JSON.stringify(this.customization))
    },
  },
}
</script>
